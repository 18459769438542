<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group
            novalidate
            class="needs-validation"
            label="Twitch username"
            label-for="twitchAccount"
          >
            <b-form-input
              id="twitchAccount"
              v-model="twitchname"
              placeholder="Geminos"
            />

          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Anzahl der Zuschauer"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="amount"
              placeholder="30"
            />
            <!--          <b-form-valid-feedback-->
            <!--            tooltip-->
            <!--          >-->
            <!--            Looks good!-->
            <!--          </b-form-valid-feedback>-->
            <b-form-invalid-feedback
              tooltip
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Dripfeed in sekunden"
            label-for="dripfeedSeconds"
          >
            <b-form-input
              id="dripfeedSeconds"
              v-model="dripfeedSeconds"
              placeholder="60"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Laufzeit in Minuten"
            label-for="durationInMinutes"
          >
            <b-form-input
              id="durationInMinutes"
              v-model="durationInMinutes"
              placeholder="5"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="raidActivated"
            style="margin-top: 30px"
            name="check-button"
            switch
            inline
          >
            RaidBot
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="chatBotActivated"
            style="margin-top: 30px"
            name="check-button"
            switch
            inline
          >
            ChatBot
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            block
            variant="success"
            @click="startOrder"
          >
            Auftrag starten!
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <!--  Here comesTheTable-->
      <b-row v-if="waitingActive">
        <b-col>
          <b-progress
            v-model="barProgress"
            max="100"
          />
        </b-col>
      </b-row>
      <b-row class="m-2">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-badge
            class="mr-1"
            variant="light-success"
          >
            {{ totalActiveViews }}
          </b-badge>

          <b-form-checkbox
            id="showTerminatedOrdersBox"
            v-model="showTerminatedOrders"
            name="check-button"
            switch
            inline
          />
          <label for="showTerminatedOrdersBox">Zeige abgelaufene Accounts</label>

        </b-col>
        <b-col
          md="2"
          sm="8"
          class="my-1"
        >
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            style="min-height: 40vh"
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(duration)="data">
              <b-progress
                :max="data.item.durationTotal"
                :variant="`${resolveProgressBarVariant(data.item)}`"
              >
                <b-progress-bar :value="data.item.durationTotal - data.item.duration">
                  <strong>{{ data.item.durationTotal - data.item.duration }} / {{ data.item.durationTotal }}</strong>
                </b-progress-bar>

              </b-progress>
              <!--          <b-badge-->
              <!--            pill-->
              <!--            :variant="`light-${resolveUserStatusVariant(data.item.duration)}`"-->
              <!--            class="text-capitalize"-->
              <!--          >-->
              <!--            {{ data.item.duration }}-->
              <!--          </b-badge>-->
            </template>
            <template #cell(viewers)="data">
              <b-badge :variant="data.item.viewers.statusOfBadge">
                {{ data.item.viewers.amountString }}
              </b-badge>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(geminosname)="data">

              <b-link
                v-if="isjemkein"
                :href="'https://console.firebase.google.com/u/0/project/geminos-4dedd/firestore/data/~2FUser~2F/' + data.item.uid"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.geminosname }}
              </b-link>
              <div
                v-if="!isjemkein"
              >
                {{ data.item.geminosname }}

              </div>
            </template>

            <template #cell(creationDate)="data">
              {{ getFormattedDate(data.item.licensebeginning) }}
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">-->
                <!--              <feather-icon icon="FileTextIcon" />-->
                <!--              <span class="align-middle ml-50">Details</span>-->
                <!--            </b-dropdown-item>-->

                <b-dropdown-item @click="extendPackage(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Extend Package by 1 day</span>
                </b-dropdown-item>
                <b-dropdown-item @click="restartUser(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Restart User</span>
                </b-dropdown-item>
                <b-dropdown-item @click="instantRestartUser(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">InstantRestart User</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeChatBot(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span
                    v-if="!data.item.chatbot"
                    class="align-middle ml-50"
                  >Activate Chatbot</span>
                  <span
                    v-if="data.item.chatbot"
                    class="align-middle ml-50"
                  >Deactivate Chatbot</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-login
                  @click="selectedUser = data.item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Change Name</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-dripfeed
                  @click="selectedUser = data.item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Change Dripfeed</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-b-modal.modal-chatlist
                    @click="selectedUser = data.item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Change Chatlist</span>
                </b-dropdown-item>

                <!--            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">-->
                <!--              <feather-icon icon="EditIcon" />-->
                <!--              <span class="align-middle ml-50">Edit</span>-->
                <!--            </b-dropdown-item>-->
                <b-dropdown-item
                  @click="stopPackage(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">End Package</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
            <template #cell(twitchname)="data">
              <b-link
                :href="'https://www.twitch.tv/' + data.item.twitchname"
                referrerpolicy="no-referrer"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.twitchname }}
              </b-link>
            </template>
          </b-table>
        </b-col>

      </b-row>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ items.length }} to {{ items.length }} of {{ items.length }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <!--              totalUserss-->
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-dripfeed"
      cancel-variant="outline-secondary"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="Login Form"
    >
      Change Dripfeed-Time for {{ selectedUser.twitchname }}
      <b-form>
        <b-form-group>
          <label>Time in seconds</label>
          <b-form-input
            v-model="newDripfeed"
            type="text"
            placeholder="3600"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          @click="saveDripfeed(selectedUser,newDripfeed), ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="modal-chatlist"
        cancel-variant="outline-secondary"
        ok-title="Login"
        cancel-title="Close"
        centered
        title="Login Form"
    >
      Change primary Chatlist for {{ selectedUser.twitchname }}
      <b-form>
        <b-form-group>
          <label>Drop the Chatlist as .txt or insert The chatList line by line</label>
          <b-form-file
              v-model="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
          />
          <b-form-textarea
              id="textarea-auto-height"
              v-model.lazy="filetext"
              debounce="5000"
              placeholder="Insert your messages line by line"
              rows="3"
              max-rows="11"
              class="mt-2"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
            variant="success"
            @click="changeChatlist(selectedUser), ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Change"
      cancel-title="Close"
      centered
      title="Change Twitchname"
    >
      Change Twitchname for {{ selectedUser.twitchname }}
      <b-form>
        <b-form-group>
          <label>Twitchname</label>
          <b-form-input
            v-model="newTwitchname"
            type="text"
            placeholder="Twitchname"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          @click="saveTwitchname(selectedUser,newTwitchname), ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'

/* eslint-disable */
import {
  BTable,
  BCard,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BLink,
  BRow,
  BProgress,
  BModal,
  VBModal,
  BForm,
  BProgressBar,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import firebase from "../../../../firebase.json";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {formatDate} from "@core/utils/filter";

export default {
  components: {
    BTable,
    BAvatar,
    BProgress,
    BProgressBar,
    BCard,
    BBadge,
    BFormCheckbox,
    BLink,
    BDropdown,
    BDropdownItem,
    BRow,
    BModal,
    BForm,
    BFormFile,
    BFormInput,
    BCol,
    BFormGroup,
    BFormTextarea,

    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      newDripfeed: 3600,
      showTerminatedOrders: false,
      barProgress: 0,
      raidActivated: false,
      value: ['Hi', 'Wie gehts dir?', 'Huhuu'],
      filetext: null,
      file: null,
      file2: null,
      amount: 30,
      chatBotActivated: false,
      dripfeedSeconds: 60,
      durationInMinutes : 30,
      totalActiveViews: 0,
      waitingActive: false,
      selectedUser: { "id": 47, "geminosname": "Notnetic", "twitchname": "ducknetic", "license": "3437-3886-3296", "duration": -56, "durationLeft": -56, "durationTotal": 103, "plan": "Starter", "viewers": { "amountString": "20/15", "statusOfBadge": "light-success" }, "status": 2, "uid": "4Mh9ehv8nrYN8ABkLfinQI7I2yH3" },
      perPage: 25,
      pageOptions: [15, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'status',
      isjemkein: false,
      sortDesc: true,
      newTwitchname: "",
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'geminosname', label: 'Geminos-Name',  sortable: true },
        { key: 'twitchname', label: 'Twitch-Name', sortable: true },
        { key: 'durationTotal', label: 'Total Duration', sortable: true },
        { key: 'plan', label: 'Plan', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true },
        { key: 'viewers', label: 'Viewers' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'creationDate', label: 'Created' , sortable: true },
        { key: 'dripfeedTime', label: 'DripFeedtime'},
        { key: 'actions', label: 'Action' },
      ],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          geminosname: 'DisATest',
          twitchname: "Korrie O'Crevy",
          license: 'Nuclear Power Engineer',
          plan: 'kocrevy0@thetimes.co.uk',
          viewers: 'Krasnosilka',
          duration: '10',
          status: 2,
        },
      ],
      status: [{
        1: 'Inactive', 2: 'Active', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-danger', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    console.log("NOIW")
    this.resolveUserData()
    this.refreshForCurrentViews()
    this.listenForUpdates()
    this.totalRows = this.items.length
  },
  watch: {
    showTerminatedOrders() {
      if(this.showTerminatedOrders) this.getTerminatedAccounts()
    },
    value(val) {
      this.filetext = val.join('\n')
    },
    file() {
      // eslint-disable-next-line prefer-destructuring
      const file = this.file
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = evt => {
        this.filetext = evt.target.result
        this.value = evt.target.result.split('\n')
        // eslint-disable-next-line no-global-assign,no-restricted-globals
        // this.name = evt.target.result
      }
      reader.onerror = evt => {
        console.error('ERROR')
        console.error(evt)
      }
    },
    filetext(val) {
      this.value = val.split('\n')
    },
  },
  methods: {
    selectedFile() {
      const { file } = this.file
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = evt => {
        this.filetext = evt.target.result
        this.value = evt.target.result.split('\n')
        // eslint-disable-next-line no-global-assign,no-restricted-globals
        // this.name = evt.target.result
      }
      reader.onerror = evt => {
        console.error('ERROR')
        console.error(evt)
      }
    },
    getFormattedDate(newDate){
      try {
        return formatDate(newDate.toDate(), { dateStyle: 'short', timeStyle: 'short' })
      } catch (e) {
        return "None"
      }
    },
    startOrder() {
      axios.post('/api/v1/startOrder', {
        key: "followerfabrik5196129",
        type: "viewer",
        twitchname: this.twitchname.toLowerCase().trim(),
        quantity: Math.round(this.amount),
        dripfeedTime: Math.round(this.dripfeedSeconds),
        durationInMinutes: this.durationInMinutes,
        raidActivated: this.raidActivated,
        chatBotActivated: this.chatBotActivated,
        chatInterval: [60,180]
      })
          .then((response) =>  {
            console.log(response);
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Created Order!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Created Order!`,
              },
            })
            this.$swal({
              title: 'Created Order',
              text: 'Created Order',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              title: 'Errors!',
              text: 'Something went wrong please check all fields',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          });
    },
    saveDripfeed(userItem, newDripfeed) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(userItem.uid).set({ Botpanel: {DripFeedTime: parseInt(newDripfeed)} }, { merge: true }).then(async result => {
        console.log("success")
      }).catch(err => {
        console.log(err)
      })
    },
    saveTwitchname(userItem, newName) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(userItem.uid).set({ twitchname: newName.toLowerCase().trim() }, { merge: true }).then(async result => {
        console.log("success")
      }).catch(err => {
        console.log(err)
      })
    },
    async changeChatlist(userItem) {
      const sx = this.$firebase.firestore()

      //DeleteAllExisting
      await sx.collection('User').doc(userItem.uid).collection("Chatlists").get().then(async result => {
        await result.forEach(singleResult => {
          console.log(singleResult.id)
          sx.collection('User').doc(userItem.uid).collection("Chatlists").doc(singleResult.id).delete()
        })
      })
      sx.collection('User').doc(userItem.uid).collection("Chatlists").add({"Category": "General", "active": true, "name": "Chatlist1", "text": this.value}).then(async result => {
        console.log("success")
      }).catch(err => {
        console.log(err)
      })
    },
    changeChatBot(item) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Chatbot": !item.chatbot} }, { merge: true }).then(async result => {
        const chatDoc = await sx.collection("User").doc("74Nj5wNgVbTnQZst6EK3").collection("Chatlists").doc("DD85QEXgDxxFMyuxc5t1").get()
        await sx.collection("User").doc(item.uid).collection("Chatlists").add(chatDoc.data())
        if(!item.chatbot){
          this.$swal({
            title: 'Enabled Chat',
            text: ' and inserted Standard-Chatlist  (needs Restart to take effect if Instance is active)',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Disabled Chat',
            text: ' (needs Restart to take effect if Instance is active)',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }

      }).catch(err => {
        console.log(err)
      })
    },
    activateNameChange(item, bool) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(item.uid).set({ License: { "changeTwitchnameAllowed": bool} }, { merge: true }).then(async result => {
        await new Promise(r => setTimeout(r, (20 * 1000)));
      }).catch(err => {
        console.log(err)
      })
    },
    resolveUserData() {
      let userData = getUserData()
      this.isjemkein= userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2';
    },
    refreshForCurrentViews() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(result => {
        result.forEach(x => {
          this.sendPostRequest(x.data().twitchname).then(newViewers => {
            if(newViewers.data.user.stream === null) {
              sx.collection('User')
                  .doc(x.id).set({
                Botpanel: {
                  Active: false,
                },
              }, { merge: true }).then(() => {
                console.log('SUCCESS UPDATED')
              })
                  .catch(err => {
                    console.log(err)
                  })
            } else {
              sx.collection('User')
                  .doc(x.id).set({
                Botpanel: {
                  currentViews: newViewers.data.user.stream.viewersCount,
                },
              }, { merge: true }).then(() => {
                console.log('SUCCESS UPDATED')
              })
                  .catch(err => {
                    console.log(err)
                  })
            }

          })
        })
      })
    },
    listenForUpdates() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Package', '==', 'FollowerFabrik').onSnapshot(async result => {
        this.items = []
        if (this.showTerminatedOrders) {
          await this.getTerminatedAccounts()
        }
        let id = 0
        this.totalActiveViews = 0
        result.forEach(x => {
          const doc = x.data()

          // doc.Botpanel?.
          let userStatus = 0
          if (doc.Botpanel.Active) {
            userStatus = 2
            this.totalActiveViews = parseInt(this.totalActiveViews) + parseInt(doc.Botpanel?.Views)

          } else userStatus = 1

          const date1 = doc.License.licensebeginning.toDate()
          const date2 = new Date()
          const differenceInTime = date2.getTime() - date1.getTime()

          // To calculate the no. of days between two dates
          const days = differenceInTime / (1000 * 3600 * 24)
          let remainingduration = doc.License.TotalDays - days.toFixed(0)

          const newObject = {
            id: id,
            // eslint-disable-next-line global-require
            geminosname: doc.username,
            twitchname: doc.twitchname,
            license: doc.License?.licensekey,
            duration: remainingduration,
            dripfeedTime: doc.Botpanel.DripFeedTime,
            durationLeft: remainingduration,
            durationTotal: doc.License.TotalDays,
            plan: doc.viewer,
            viewers: this.resolveViews(doc),
            status: userStatus,
            durationInMinutes: doc.License?.durationInMinutes,
            uid: x.id,
            licensebeginning: doc.License.licensebeginning,
            chatbot: doc.Botpanel.Chatbot
          }
          this.items.push(newObject)
          id += 1
        })
        console.log(this.items)

      })
    },
    async getTerminatedAccounts() {
      const sx = this.$firebase.firestore()
      return await sx.collection('User').where("License.TerminatedAcc", "==", "FollowerFabrik").get().then((result) => {
        let id = 0
        this.totalActiveViews = 0
        result.forEach(x => {
          const doc = x.data()
          // doc.Botpanel?.
          let userStatus = 0
          if (doc.Botpanel.Active) {
            userStatus = 2
            this.totalActiveViews = this.totalActiveViews + doc.Botpanel?.Views

          } else userStatus = 1

          const date1 = doc.License.licensebeginning.toDate()
          const date2 = new Date()
          const differenceInTime = date2.getTime() - date1.getTime()

          // To calculate the no. of days between two dates
          const days = differenceInTime / (1000 * 3600 * 24)
          let remainingduration = doc.License.TotalDays - days.toFixed(0)

          const newObject = {
            id: id,
            // eslint-disable-next-line global-require
            geminosname: doc.username,
            twitchname: doc.twitchname,
            license: doc.License?.licensekey,
            duration: remainingduration,
            dripfeedTime: doc.DripFeedTime,
            durationLeft: remainingduration,
            durationTotal: doc.License.TotalDays,
            plan: doc.viewer,
            viewers: this.resolveViews(doc),
            status: userStatus,
            durationInMinutes: doc.License?.durationInMinutes,
            licensebeginning: doc.License.licensebeginning,
            uid: x.id,
            chatbot: doc.Botpanel.Chatbot
          }
          this.items.push(newObject)
          id += 1
        })
        console.log(this.items)

      })

    },
    InstantRestartAllActive() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(result => {
        sx.collection('System')
            .doc('NGROK')
            .get()
            .then(snap2 => {
              let count = 0
              result.forEach(x => {
                count += 1
                let doc = x.data()
                if(doc.Package === "FollowerFabrik") this.instantRestartUser({"uid": x.id})
              })
            })
      })
    },
    startActive() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(result => {
        sx.collection('System')
          .doc('NGROK')
          .get()
          .then(snap2 => {
            let count = 0
            result.forEach(x => {
              count += 1
              setTimeout(() => {
                fetch(`https://${snap2.data().Link}.ngrok.io/start/${x.id}`)
                  .then(response => {
                    sx.collection('User').doc(x.id).set({"License": { "LastStart": new Date() } }, { merge: true })
                  })
              }, 3000 * count)
              console.log(`https://${snap2.data().Link}.ngrok.io/start/${x.id}/${x.data().Botpanel.Chatbot}/${x.data().twitchname}/${x.data().Botpanel.Views}/${x.data().Botpanel.chatinterval[0]}/${x.data().Botpanel.chatinterval[1]}`)
            })
          })
      })
    },
    resolveProgressBarVariant(item) {
      if (item.duration < item.durationTotal / 2 && item.duration > item.durationTotal / 4) return 'primary'
      if (item.duration < item.durationTotal / 4 && item.duration > 1) return 'warning'
      if (item.duration < 1) return 'danger'
      return 'info'
    },
    async restartUser(item) {
      const sx = this.$firebase.firestore()
      this.waitingActive = true
      this.barProgress = 0
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": false} }, { merge: true }).then(async result => {
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (500)));
          this.barProgress += 100/20
        }
        const snap2 = await sx.collection('System').doc('NGROK').get()
        await fetch(`https://${snap2.data().Link}.ngrok.io/start/${item.uid}`)
        sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": true}, "License": { "LastStart": new Date() } }, { merge: true })
        this.waitingActive = false
        this.barProgress = 0
      }).catch(err => {
        console.log(err)
      })
    },
    async instantRestartUser(item) {
      this.waitingActive = true
      const sx = this.$firebase.firestore()
      const userDoc = await sx.collection('User').doc(item.uid).get()
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": false, DripFeedTime: 25} }, { merge: true }).then(async result => {
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (1000)));
          this.barProgress += 100/40
        }
        const snap2 = await sx.collection('System').doc('NGROK').get()
        await fetch(`https://${snap2.data().Link}.ngrok.io/start/${item.uid}`)
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (1000)));
          this.barProgress += 100/40
        }
        sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": true, DripFeedTime: userDoc.data().Botpanel.DripFeedTime}, "License": { "LastStart": new Date() } }, { merge: true })
        this.waitingActive = false
        this.barProgress = 0
      }).catch(err => {
        console.log(err)
      })
    },
    extendPackage(item) { //TODO ÄNDERn
      const sx = this.$firebase.firestore()
      const obj = {
        TotalDays: item.durationTotal + 1,
        MinutesTotal: Math.round(item.MinutesTotal) +1440
      }
      // sx.collection('userTest').doc(item.id).update({ test: obj })
      sx.collection('User').doc(item.uid).set({ License: obj }, { merge: true })
    },
    stopPackage(item) {
      console.log("ENDINGPACKAGE")
      console.log(item)
      const sx = this.$firebase.firestore()
      let obj = {}
      if (item.durationTotal === undefined) {
        obj = {
          licenseKeyOld: item.license, licensekey: 'none', TotalDays: 0, TotalDaysOld: 0,
        }
      } else {
        obj = {
          licenseKeyOld: item.license, licensekey: 'none', TotalDays: 0, TotalDaysOld: item.durationTotal, TerminatedAcc: "FollowerFabrik",
        }
      }
      const bpObj = {
        Active: false,
      }
      console.log(item.uid)
      sx.collection('User').doc(item.uid).set({ License: obj, Package: 'None', Botpanel: bpObj }, { merge: true }).then(result => {
        console.log(result)
      }).catch(err => {
        console.log(err)
      })
    },
    resolveViews(item) {
      let amountString = ""
      let statusOfBadge = "light-success"
      if (item.Botpanel?.currentViews === null || item.Botpanel?.currentViews === undefined) {
        amountString= `0 / ${item.Botpanel?.Views}`
      } else amountString= `${item.Botpanel.currentViews}/${item.Botpanel?.Views}`
      let extraTime = 60000 * 1
      let calculatedDate = new Date(item.License?.LastStart.toDate().getTime() + item.Botpanel.DripFeedTime*1000 + extraTime)
      if(item.Botpanel.currentViews-item.Botpanel?.Views < 0) statusOfBadge = "light-danger"
      if(calculatedDate > new Date()) statusOfBadge = "light-warning"
      if(!item.Botpanel.Active) statusOfBadge = "light-secondary"
      let views = {
        amountString,
        statusOfBadge
      }
      return views

    },

    async sendPostRequest(username) {
      const userAgent = {
        Windows: {
          CHROME: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.104 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:84.0) Gecko/20100101 Firefox/84.0',
        },
        Linux: {
          CHROME: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.96 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
        },
      }
      const clientId = 'kimne78kx3ncx6brgo4mv6wki5h1ko'
      const jsonOperation = {
        operationName: 'VideoPlayerStreamInfoOverlayChannel',
        variables: {
          channel: username,
        },
        extensions: {
          persistedQuery: {
            version: 1,
            sha256Hash: 'a5f2e34d626a9f4f5c0204f910bab2194948a9502089be558bb6e779a9e1b3d2',
          },
        },
      }

      const response = await fetch(
        'https://gql.twitch.tv/gql', {
          method: 'POST',
          headers: {
            Authorization: 'Oauth c7oso8b4qr09bd3gno0yqykvgv86wy',
            'Client-Id': clientId,
            'User-Agent': userAgent,
          },
          body: JSON.stringify(jsonOperation),
        },
      )
      return response.json()
    },
    resolveStatus(item) {
      console.log(item.Botpanel?.Active)
      if (item.Botpanel?.Active) {
        return 'Started'
      }
      return 'Stopped'
    },
    getTwitchLink(user) {
      return `https://www.twtich.tv/${user.twitchname}`
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
